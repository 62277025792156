import getFrameData from './locationUtils';

const excludedFromLocalStorage = ['test', 'manual', 'testFailed'];

const priorityFunctions = {
  localstorage_priority: getBankFromLocalStore,
  database_priority: getBankFromDB,
  code_priority: getBankByCode,
};

function getBankFromLocalStore() {
  try {
    return JSON.parse(window.localStorage.getItem('bank'));
  } catch {
    return null;
  }
}

function saveBankToLocalStore(selectedBank) {
  try {
    if (!excludedFromLocalStorage.includes(selectedBank)) {
      window.localStorage.setItem('bank', JSON.stringify(selectedBank));
    }
  } catch {
    // skip
  }
}

function getBankFromDB(transactionDetails) {
  if (transactionDetails?.preselectedDbBank) {
    return transactionDetails.preselectedDbBank;
  }
  return null;
}

function getBankByCode(_, bankList) {
  console.log('bankList:', bankList);
  const bankCode = getFrameData().bank_code;
  console.log('bankCode:', bankCode);
  const bank = bankList.find((bank) => bank.code === bankCode);

  console.log('bank:', bank);
  return bank?.name;
}

function getPreselectedBankName(transactionDetails, avaiableBanks) {
  const priorities = getPriorities(transactionDetails);

  console.log('priorities:', priorities);

  for (const priority of priorities) {
    const bankName = priorityFunctions[priority](transactionDetails, avaiableBanks);

    console.log('bankName:', bankName);

    if (bankName && bankName !== 'test' && bankName !== 'testFailed') {
      return bankName;
    }
  }

  return null;
}

function getPriorities(transactionDetails) {
  const { priorities } = transactionDetails;
  return Object.keys(priorities)
    .filter((key) => priorities[key] !== '0')
    .sort((a, b) => priorities[a] - priorities[b]);
}

export { getPreselectedBankName, saveBankToLocalStore };
export default getPreselectedBankName;

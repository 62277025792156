import { writable, get } from 'svelte/store';

import { getBanks } from '../api';

import { setError } from './error.store';
import transaction from './transaction.store';

import { isShowAll } from '../utils/locationUtils';
import filterByQuery from '../utils/searchQueryUtils';

const MERCHANT_TEST_LIST = [
  {
    name: 'test',
    nameJapanese: 'Test Transaction',
    nameEnglish: 'Test',
    avaiable: true,
    maintenance: { enabled: false },
  },
  {
    name: 'manual',
    nameJapanese: 'Test Manual',
    nameEnglish: 'Test Manual',
    maintenance: { enabled: false },
  },
  {
    name: 'testFailed',
    nameJapanese: 'Test Failed Transaction',
    nameEnglish: 'Test Failed Transaction',
    maintenance: { enabled: false },
  },
];

let fetchedBanksCache = [];

const banks = writable([]);

async function setBanks() {
  if (APH_ENV === 'test' && !isShowAll()) {
    // Test env - displays test transaction only if query is not set to ?showall=true

    banks.update((prevBanks) => {
      return [...prevBanks, ...MERCHANT_TEST_LIST];
    });
    fetchedBanksCache = MERCHANT_TEST_LIST;
  } else if (fetchedBanksCache.length > 0) {
    // Prevent multiple fetches on return to bank list
    banks.update((prevBanks) => {
      return [...prevBanks, ...fetchedBanksCache];
    });
  } else {
    const transactionRef = get(transaction);
    const { merchantId } = transactionRef;
    getBanks(merchantId)
      .then((fetchedBanks) => {
        if (fetchedBanks.length === 0) {
          setError('InternalError');
        } else {
          banks.update((prevBanks) => {
            return [...prevBanks, ...fetchedBanks];
          });
          fetchedBanksCache = fetchedBanks;
        }
      })
      .catch((e) => {
        setError('connectionLost');
      });
  }
}

export const setSearchQuery = (filter) => {
  const filterdList = filterByQuery(fetchedBanksCache, filter);

  banks.update(() => filterdList);
};
export { setBanks, banks };
export default banks;
